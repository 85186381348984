// Parallax function
function parallax() {
	var wScroll = window.scrollY;
	var width = 244 - wScroll * ((244 - 123) / 60) + "px";
	var height = 99 - wScroll * ((99 - 50) / 60) + "px";
  
	document.getElementById('header-content').style.top = wScroll * 0.5 + 'px';
  
	if (wScroll < 60 && window.innerWidth >= 1000) {
	  document.querySelector("nav").style.top = (30 - wScroll * 0.5) + 'px';
	  document.querySelector(".nav-bg").style.opacity = (0 + wScroll * 0.025);
	  document.getElementById("logo").style.width = width;
	  document.getElementById("logo").style.height = height;
	} else {
	  document.querySelector("nav").style.top = 0 + 'px';
	  document.querySelector(".nav-bg").style.opacity = 1;
	  document.getElementById("logo").style.width = '123px';
	  document.getElementById("logo").style.height = '50px';
	}
}
  
function checkIntersection(entries, observer) {
	entries.forEach(entry => {
	  if (entry.intersectionRatio > 0.5) {
		// Element takes more than 50% of the viewport
		  // Add your logic here
		  const targetID = entry.target.id;
		  if (document.querySelector(`.menu-item[data-target="${targetID}"]`)) { 
			  document.querySelectorAll('#menu li a').forEach(function (link) {
				  link.classList.remove("menu-active");
			  })
			  document.querySelector(`.menu-item[data-target="${targetID}"] a`).classList.add("menu-active");
			
		  }
	  } else {
		// Element takes less than 50% of the viewport
		// Add your logic here
	  }
	});
  }
  
  // Create an intersection observer
  const observer = new IntersectionObserver(checkIntersection, { threshold: 0.5 });
  
  // Observe the target element
const targets = document.querySelectorAll('section');
targets.forEach(target => {
	observer.observe(target);
})
  
  
  
  // Event listener for scroll
  document.addEventListener('DOMContentLoaded', function() {
	window.addEventListener("scroll", parallax);
  });
  
  // Menu toggle function
  function menuToggle() {
	document.getElementById('menu-mobile').classList.toggle('active');
	document.querySelector('.burger-icon').classList.toggle('x');
  }